import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {UsersService} from '../../../services/users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {SessionService} from '../../../services/session/session.service';

interface Manager {
  name: string;
  email: string;
  role: string;
}

interface User {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  id: string;
}

@Component({
  selector: 'app-editcompanymanagersdialog',
  templateUrl: './editcompanymanagersdialog.component.html',
  styleUrls: ['./editcompanymanagersdialog.component.scss']
})
export class EditCompanyManagersDialogComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public company;
  public form;
  public managers = new MatTableDataSource<Manager>();
  public foundUsers: Array<User> = [];
  public columnsToDisplay = ['name', 'email', 'role', 'actions'];
  public selectedUser: User|undefined;
  public mode;
  public roleName;

  constructor(
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private usersService: UsersService,
      private teamCompanyUsers: TeamCompanyUsersService,
      private sessionService: SessionService,
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<EditCompanyManagersDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EditCompanyManagersDialogModel
  ) {
    this.company = data.company;
    this.mode = data.mode;
    this.roleName = this.mode;
    this.form = this.formBuilder.group({
      role: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
    this.form.get('email')?.valueChanges.subscribe(value => {
      if (value) {
        this.usersService.queryUsers(value).then(result => {
          this.foundUsers = result.slice(0, 10);
          this.selectedUser = undefined;
        });
      } else {
        this.foundUsers = [];
      }
    });
  }

  ngOnInit(): void {
    this.loadUsers();
    if (this.mode === 'support') {
      this.form.get('role')?.setValue('supportagent');
    }
    if (this.mode === 'members') {
      this.form.get('role')?.setValue('member');
    }
  }

  private loadUsers(): void {
    this.teamCompanyUsers.getCompanyUsers(this.company.id).then(result => {
      this.managers.data = result;
      this.managers.sort = this.sort;
    });
  }

  okClicked(): void {
    this.dialogRef.close(undefined);
  }

  capitalizeFirstLetter(thisString: string): string {
    return thisString[0].toUpperCase() + thisString.slice(1);
  }

  deleteMember(member: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove this ${this.roleName}?`).then(ok => {
      if (ok) {
        console.log('DELETE MEMBER', member)
        this.teamCompanyUsers.deleteCompanyUser(member.id).then(result => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `${this.capitalizeFirstLetter(this.roleName)} removed`});
          this.clearFields();
          this.loadUsers();
        });
      }
    });
  }

  clearFields(): void {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  newMemberClicked(): void {
    this.teamCompanyUsers.createCompanyUser(this.company.id, this.selectedUser?.id || '', this.form.get('role')?.value).then(result => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `New ${this.roleName} added`});
      this.clearFields();
      this.loadUsers();
    });
  }

  userSelected(user: User): void {
    this.form.get('email')?.setValue(user.email, {emitEvent: false});
    this.selectedUser = user;
    this.foundUsers = [];
  }

  loginManager(user: User): void {
    this.sessionService.loginByUserId(user.userId);
  }

  inviteClicked(): void {
    this.teamCompanyUsers.inviteCompanyManager(this.company.name, this.form.get('email')?.value).then(_ => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `${this.capitalizeFirstLetter(this.roleName)} invitation sent`});
      this.clearFields();
    });
  }

  playHelpMovie(): void {
    this.uiAlertService.presentMovie('Add/Remove Support Agents' , UiAlertService.content.ADD_REMOVE_SUPPORT_AGENTS);
  }

}

export class EditCompanyManagersDialogModel {
  constructor(public company: any, public mode: 'all'|'support'|'members' = 'all') {
  }
}
