import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';

let rememberedClientId = '';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  public selectedClientId = '';
  public clients: any;
  public info: any;

  public links = [
    { title: 'Census', link: '/brokeragency/clients/client-census'},
    { title: 'Eligibility', link: '/brokeragency/clients/client-eligibility'},
    { title: 'Enrollment', link: '/brokeragency/clients/client-enrollment'}
  ];

  public activeLink: any;

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private router: Router,
      private usersInsuranceService: UsersInsuranceService,
  ) { }

  ngOnInit(): void {
    this.selectedClientId = rememberedClientId;
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamCompaniesService.getCompanies().then((companies: any) => {
      this.clients = companies.filter((company: { type: string; }) => company.type === 'employer');
      if (!this.selectedClientId) {
        this.setClient({value: this.clients[0].id});
        this.getCounts();
      }
    });
    this.usersInsuranceService.insuranceStateChangedObservable.subscribe(() => {
      this.getCounts();
    });
  }

  private getCounts(): void {
    if (this.selectedClientId) {
      this.usersInsuranceService.getCounts(this.selectedClientId).then(result => {
        this.info = [
          {title: 'Census', value: result.census, background: 'lightskyblue'},
          {title: 'Eligible', value: result.eligible, background: 'orange'},
          {title: 'Enrolled', value: result.enrolled, background: 'mediumturquoise'},
          {title: 'Invited', value: result.invited, background: 'mediumorchid'},
          {title: 'Signed Up', value: result.signedup, background: 'yellowgreen'},
        ];
      });
    }
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

  setClient(event: any): void {
    this.selectedClientId = event.value;
    this.getCounts();
    rememberedClientId = this.selectedClientId;
  }

}
