import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from '../../services/api/api.service';
import {SessionService} from '../../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      public apiService: ApiService,
      private sessionService: SessionService,
      public router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.apiService.isAuthenticated()) {
      this.sessionService.loginUrl = state.url;
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
