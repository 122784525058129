<div class="topline">
    <h1>Broker</h1>
    <mat-form-field>
        <mat-label>Current Broker</mat-label>
        <mat-select (selectionChange)="setBroker($event)" [(value)]="selectedBrokerId">
            <mat-option *ngFor="let broker of brokers" [value]="broker.id">{{broker.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<app-infoband [items]="info"></app-infoband>
<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<ng-container *ngIf="!selectedBrokerId">
    <app-inlinemessage class="info" level="info" message="Please select a broker so that you can update their profile, agencies, and products"></app-inlinemessage>
</ng-container>
<ng-container *ngIf="selectedBrokerId">
    <app-broker-profile [companyId]="selectedBrokerId" tabPanel *ngIf="activeLink?.title === 'Profile'"></app-broker-profile>
    <app-broker-agencies (countEvent)="setCount('Agencies', $event)" [companyId]="selectedBrokerId" tabPanel [enabled]="activeLink?.title === 'Agencies'"></app-broker-agencies>
    <app-broker-products (countEvent)="setCount('Products', $event)" [companyId]="selectedBrokerId" tabPanel [enabled]="activeLink?.title === 'Products'"></app-broker-products>
</ng-container>
