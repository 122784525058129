import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompanyUsersService} from '../../services/teamcompanyusers/teamcompanyusers.service';
import {UsersService} from '../../services/users/users.service';

interface TeamCompanyUser {
  company: string;
  role: string;
  createdAt: string;
  name: string;
}

@Component({
  selector: 'app-products-permissions',
  templateUrl: './products-permissions.component.html',
  styleUrls: ['./products-permissions.component.scss']
})
export class ProductsPermissionsComponent implements OnInit {

  public permissions = new MatTableDataSource<TeamCompanyUser>();

  public columnsToDisplay = ['companyName', 'role', 'createdAt', 'name'];

  constructor(
      private teamCompanyUsersService: TeamCompanyUsersService,
      private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.teamCompanyUsersService.getCompanyUsersByUserId(this.usersService.getCurrentUserId()).then(result => {
      this.permissions.data = result;
    });
  }

}
