import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersInsuranceService {

    public insuranceStateChangedObservable = new BehaviorSubject(null);

    constructor(
        private restangular: Restangular,
    ) { }

    // PRIMARY

    getInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).get().toPromise();
    }

    createInsurancePrimary(userId: string, gender: string, metadata: any, tobaccoUseDate: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
        }).toPromise();
    }

    modifyInsurancePrimary(userId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsurancePrimary(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/primary', userId).remove().toPromise();
    }

    // DEPENDENTS

    getInsuranceDependents(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).getList().toPromise();
    }

    createInsuranceDependent(userId: string, gender: string, metadata: any, tobaccoUseDate: string, firstName: string, lastName: string, dob: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).customPOST({
            gender,
            metadata,
            tobaccoUseDate,
            firstName,
            lastName,
            dob,
            relationship,
        }).toPromise();
    }

    modifyInsuranceDependent(userId: string, dependentId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceDependent(userId: string, dependentId: string): Promise<any> {
        return this.restangular.one('users/insurance/dependent', userId).one(dependentId).remove().toPromise();
    }

    // BENEFICIARIES

    getInsuranceBeneficiaries(userId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).getList().toPromise();
    }

    createInsuranceBeneficiary(userId: string, beneficiaryType: string, firstName: string, lastName: string, ssn: string, relationship: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).customPOST({
            beneficiaryType,
            firstName,
            lastName,
            ssn,
            relationship,
        }).toPromise();
    }

    modifyInsuranceBeneficiary(userId: string, beneficiaryId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).customPUT({
            ...fields
        }).toPromise();
    }

    deleteInsuranceBeneficiary(userId: string, beneficiaryId: string): Promise<any> {
        return this.restangular.one('users/insurance/beneficiary', userId).one(beneficiaryId).remove().toPromise();
    }

    // CENSUS-ELIGIBILITY-ENROLLMENT STATE

    getCounts(companyId: string): Promise<any> {
        return this.restangular.one('users/insurance/all').one(companyId, 'counts').get().toPromise();
    }

    getInsuranceState(companyId: string, state: string): Promise<any> {
        return this.restangular.one('users/insurance', state).one(companyId).getList().toPromise();
    }

    createInsuranceState(companyId: string, userId: string, email: string, metadata: any, state: string): Promise<any> {
        metadata = {upload: metadata};
        return this.restangular.one('users/insurance', state).one(companyId).customPOST({
            companyId,
            userId,
            email,
            metadata,
            state,
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    modifyInsuranceState(stateId: string, fields: any): Promise<any> {
        return this.restangular.one('users/insurance/state', stateId).customPUT({
            ...fields
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });;
    }

    deleteInsuranceState(stateId: string): Promise<any> {
        return this.restangular.one('users/insurance/state', stateId).remove().toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    uploadInsuranceState(companyId: string, state: string, data: any): Promise<any> {
        return this.restangular.one('users/insurance', state).one('upload', companyId).customPUT(data, undefined, undefined, { 'content-type': 'text/html' }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });
    }

    invite(companyId: string, ids: Array<string>): Promise<any> {
        return this.restangular.one('users/insurance/enrolled').one('invite', companyId).customPUT({
            ids
        }).toPromise().then((result: any) => {
            this.insuranceStateChangedObservable.next(null);
            return result;
        });;
    }

}
