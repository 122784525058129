import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-broker-products',
  templateUrl: './broker-products.component.html',
  styleUrls: ['./broker-products.component.scss']
})
export class BrokerProductsComponent implements OnInit, OnChanges {

  @Input() companyId = '';
  @Input() enabled = false;
  @Output() countEvent = new EventEmitter<number>();

  public columnsToDisplay = ['vendorName', 'vendorSlug', 'productName', 'productSlug', 'actionmenu'];
  public vendorProducts = new MatTableDataSource<any>();

  constructor(
    private vendorProductsService: VendorProductsService,
    private teamCompaniesService: TeamCompaniesService,
    private snackBar: MatSnackBar
  ){
  }

  ngOnInit(): void {
  }

  private loadItems(): void {
    this.vendorProductsService.getAllVendorProducts().then(vendorProducts => {
      this.teamCompaniesService.getCompany(this.companyId).then(company => {
        this.vendorProducts.data = [];
        company.payrollConfig.products?.forEach((productId: any) => {
          this.vendorProducts.data.push(vendorProducts.find((vendorProduct: { id: any; }) => vendorProduct.id === productId));
        });
        this.countEvent.emit(this.vendorProducts.data.length);
      });
    });
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  assignToAgencies(product: any): void {
    this.snackBar.openFromComponent(SnackBarComponent, {data: `Coming soon. Use Set Products for Agency on items in Agencies tab`});
  }

  removeFromAgencies(product: any): void {
    this.snackBar.openFromComponent(SnackBarComponent, {data: `Coming soon. Use Set Products for Agency on items in Agencies tab`});
  }

}
