import {Component, Input} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-census',
  templateUrl: './client-census.component.html',
  styleUrls: ['./client-census.component.scss']
})
export class ClientCensusComponent {

  @Input() clientId = '';

  public table: Table = {
    title: 'Census Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 1', label: 'Address 1', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 2', label: 'Address 2', fieldType: FieldType.F_STRING},
      {name: 'City', label: 'City', fieldType: FieldType.F_STRING, required: true},
      {name: 'State', label: 'State', fieldType: FieldType.F_STRING, required: true},
      {name: 'ZIP', label: 'ZIP Code', fieldType: FieldType.F_STRING, required: true},
      {name: 'Email', label: 'Email Address', fieldType: FieldType.F_EMAIL, required: true},
      {name: 'Phone', label: 'Phone Number', fieldType: FieldType.F_PHONE, required: true},
      {name: 'Date of Birth', label: 'Date of Birth', fieldType: FieldType.F_PASTDATE, required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
      {name: 'Smoking', label: 'Smoking', fieldType: FieldType.F_DROPDOWN, values: ['Yes', 'No'], required: true}
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      },
      {
        data: (row: { [x: string]: any; }) => row.state,
        label: 'Status'
      }
    ],
    menuItems: [
      {name: 'Make Eligible', disabled: this.eligibleDisabled.bind(this), click: this.makeEligible.bind(this)}
    ],
    templateUrl: '/assets/files/Wallit-census.xlsx'
  };

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar
  ) {

  }

  eligibleDisabled(item: any): boolean {
    return item.state !== 'census';
  }

  makeEligible(item: any): Promise<boolean> {
    return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'eligible'}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to eligible' });
      return true;
    });
  }

}
