import { Injectable } from '@angular/core';
import {HttpEvent, HttpRequest, HttpInterceptor, HttpHandler, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && error?.error?.error === 'Not trusted device!') {
                    const userEmail: string = req.body?.username;
                    if (userEmail) {
                        void this.router.navigate(['2fa/options', userEmail]);
                    }
                }
                return throwError(() => error as unknown as HttpEvent<any>);
            })
        );
    }

}
