import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

export interface CompanyInfo {
    id: string;
    name: string;
    payrollConfig: string;
    domainName: string;
    parentId: string;
}

@Injectable({
    providedIn: 'root'
})
export class TeamCompaniesService {

    constructor(
        private restangular: Restangular,
    ) { }

    getCompany(id: string): Promise<any> {
        return this.restangular.one('teams').one('companies', id).get().toPromise();
    }

    getCompanies(): Promise<any> {
        return this.restangular.one('teams').one('companies').getList().toPromise();
    }

    deleteCompany(companyId: string): Promise<any> {
        return this.restangular.one('teams').one('companies', companyId).remove().toPromise();
    }

    modifyCompany(companyId: string, fields: any): Promise<any> {
        return this.restangular.one('teams').one('companies', companyId).customPUT({
            ...fields
        }).toPromise();
    }

    createCompany(name: string, payrollConfig: any, domainName: string, type: string, parentId: string,
                  EIN: string, SIC: string, address1: string, address2: string, city: string, state: string, postcode: string): Promise<any> {
        return this.restangular.one('teams').one('companies').customPOST({
            name,
            payrollConfig,
            domainName,
            type,
            parentId,
            EIN, SIC, address1, address2, city, state, postcode
        }).toPromise();
    }

    uploadLogo(companyId: string, data: any): Promise<any> {
        return this.restangular.one('teams').one('companies', companyId).one('logo').customPUT(data, undefined, undefined, { 'content-type': 'image/*' }).toPromise();
    }
}
