<div class="loginwrapper">
    <img class="logo" [src]="logoUrl">
    <mat-card class="login-card">
      <mat-card-title *ngIf="isCustomerSupport">Customer Support Login</mat-card-title>
      <mat-card-title *ngIf="!isCustomerSupport">{{isIntegrationAdmin ? 'Integration' : (isBrokerAdmin ? 'Broker' : 'Team')}} Admin Login</mat-card-title>
      <mat-card-content>
        <form [formGroup]="form">
          <div *ngIf="!emailCodeInput">
            <p>
              <mat-form-field>
                <input type="text" matInput placeholder="Email" formControlName="username">
              </mat-form-field>
            </p>
            <p *ngIf="!loginWithoutPassword">
              <mat-form-field>
                <input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Password" formControlName="password">
                  <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                      <mat-icon class="hide-password-icon" [svgIcon]="hidePassword ? 'icon-eye-lock' : 'icon-eye'"></mat-icon>
                  </button>
              </mat-form-field>
            </p>
          </div>
          <div class="code-input-content" *ngIf="emailCodeInput">
            <p>We've sent a 6-digit code to your email. The code expires shortly, so please enter it soon.</p> 
            <code-input #codeInput
                    [isCodeHidden]="false"
                    [isNonDigitsCode]="false"
                    [codeLength]="6"
                    (codeCompleted)="onCodeCompleted($event)">
            </code-input>

              <div class="button resend-email-code-btn" *ngIf="loginWithoutPassword && emailCodeInput">
                  <button mat-button (click)="sendEmail()" [disabled]="this.form.get('username')?.value === ''">
                      <span *ngIf="!showResendEmailCodeTimerCounter">Resend email code</span>
                      <span *ngIf="showResendEmailCodeTimerCounter" class="resend-email-code-timer">
                          You can resend the email code in <strong>{{ resendEmailCodeTimerCounter }}</strong> seconds.
                      </span>
                  </button>
              </div>
          </div>
          <p *ngIf="error" class="error">
            {{ error }}
          </p>
          <div class="button" *ngIf="!loginWithoutPassword">
            <button type="submit" color="primary" mat-raised-button (click)="loginClicked()">Login</button>
          </div>
          <div class="button" *ngIf="loginWithoutPassword && !emailCodeInput">
            <button mat-button (click)="sendEmail()" [disabled]="this.form.get('username')?.value === ''">Send email code</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <span class="login-w-passwd" (click)="loginWithoutPassword = !loginWithoutPassword; emailCodeInput = false">
      Login {{loginWithoutPassword ? 'with' : 'without'}} password
    </span>
    <span class="version">Version {{info}}</span>
</div>
