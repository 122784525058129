import {Component, Input} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-client-enrollment',
  templateUrl: './client-enrollment.component.html',
  styleUrls: ['./client-enrollment.component.scss']
})
export class ClientEnrollmentComponent {

  @Input() clientId = '';

  private invitationFilter = 'all';
  public reload = false;

  public table: Table = {
    title: 'Enrolled Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 1', label: 'Address 1', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 2', label: 'Address 2', fieldType: FieldType.F_STRING},
      {name: 'City', label: 'City', fieldType: FieldType.F_STRING, required: true},
      {name: 'State', label: 'State', fieldType: FieldType.F_STRING, required: true},
      {name: 'ZIP', label: 'ZIP Code', fieldType: FieldType.F_STRING, required: true},
      {name: 'Email', label: 'Email Address', fieldType: FieldType.F_EMAIL, required: true},
      {name: 'Phone', label: 'Phone Number', fieldType: FieldType.F_PHONE, required: true},
      {name: 'Date of Birth', label: 'Date of Birth', fieldType: FieldType.F_PASTDATE, required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
      {name: 'Smoking', label: 'Smoking', fieldType: FieldType.F_DROPDOWN, values: ['Yes', 'No'], required: true},
      {name: 'Primary SSN', label: 'Primary Social Security Number', fieldType: FieldType.F_SSN, required: false},
      {name: 'Type', label: 'Coverage Type', fieldType: FieldType.F_DROPDOWN, values: ['Single:single', 'Partner:partner', 'Single Parent Family:spf', 'Two Parent Family:tpf'], required: true},
      {name: 'Relationship', label: 'Relationship', fieldType: FieldType.F_DROPDOWN, values: ['Self:self', 'Partner:partner', 'Dependent:dependent'], required: true},
      {name: 'Product', label: 'Product', fieldType: FieldType.F_STRING, required: true},
      {name: 'Enrollment Date', label: 'Enrollment Date', fieldType: FieldType.F_FUTUREDATE, required: true},
      {name: 'Start Date', label: 'Start Date', fieldType: FieldType.F_FUTUREDATE, required: true}
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      }
    ],
    menuItems: [
    ],
    rowFilter: row => this.filterRowItem(row),
    templateUrl: '/assets/files/Wallit-enrollment.xlsx'
  };

  filterRowItem(row: any): boolean {
    switch (this.invitationFilter) {
      case 'all':
        return true;
      case 'invited':
        return row.metadata.invited;
      case 'signedup':
        return !!row.userId;
    }
    return true;
  }

  setEmailFilter(event: MatSelectChange): void {
    this.invitationFilter = event.value;
    this.reload = !this.reload;
  }

}
