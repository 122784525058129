import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() itemCount = 0;
  @Input() pageSize = 25;
  @Input() pageIndex = 0;
  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  // @ts-ignore
  @ViewChild('paginator') paginator: MatPaginator;

  constructor() { }

  ngOnInit(): void {
  }

  pageEventHandler(event: PageEvent): void {
    this.pageEvent.emit(event);
  }

}
