import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {EditCompanyDialogComponent, EditCompanyDialogModel} from '../../modal/editcompanydialog/editcompanydialog.component';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Dictionary} from 'highcharts';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MatSort} from '@angular/material/sort';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {
  CompanyProductsDialogComponent,
  CompanyProductsDialogModel
} from '../../modal/companyproductsdialog/companyproductsdialog.component';
import * as _ from 'lodash';
import {rememberedBrokerId} from '../broker/broker.component';

@Component({
  selector: 'app-broker-agencies',
  templateUrl: './broker-agencies.component.html',
  styleUrls: ['./broker-agencies.component.scss']
})
export class BrokerAgenciesComponent implements OnInit, OnChanges {

  @Input() companyId = '';
  @Input() enabled = false;
  @Output() countEvent = new EventEmitter<number>();
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public columnsToDisplay = ['name', 'city', 'state', 'actionmenu'];
  public agencies = new MatTableDataSource<any>();
  private allCompanies!: any[];

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private uiAlertService: UiAlertService,
      private spinnerService: SpinnerService
  ){
  }

  ngOnInit(): void {
    this.loadItems();
    this.agencies.sort = this.sort;
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.teamCompaniesService.getCompanies().then(companies => {
      this.allCompanies = companies;
      this.agencies.data = companies.filter((company: { parentId: string; }) => company.parentId === this.companyId);
      this.countEvent.emit(this.agencies.data.length);
    });
  }

  addAgency(): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel({}, 'agency', 'Agency')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.teamCompaniesService.createCompany(dialogResult.name, dialogResult.payrollConfig, dialogResult.domainName, dialogResult.type, this.companyId,
          dialogResult.EIN, dialogResult.SIC, dialogResult.address1, dialogResult.address2, dialogResult.city, dialogResult.state, dialogResult.postcode).then(company => {
        this.teamCompaniesService.uploadLogo(company.id, dialogResult.imageData).then(result => {
          this.teamCompaniesService.modifyCompany(company.id, {logoUrl: result.url}).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `New agency ${dialogResult.name} added`});
          });
          this.loadItems();
        });
      });
    });
  }

  editAgency(agency: any): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel(agency, undefined)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.teamCompaniesService.uploadLogo(agency.id, dialogResult.imageData).then(result => {
        dialogResult.logoUrl = result.url;
        delete dialogResult.imageData;
        this.teamCompaniesService.modifyCompany(agency.id, dialogResult).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Agency ${agency.name} updated`});
          this.loadItems();
        });
      });
    });
  }

  removeAgency(agency: any): void {
    if (this.allCompanies.find((company: { id: any; }) => company.id === agency.id)) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `You cannot remove agency ${agency.name} because they have active clients`});
    } else {
      this.uiAlertService.presentAlertConfirm(`Do you really want to remove the ${agency.name} agency?`).then(ok => {
        if (ok) {
          this.teamCompaniesService.deleteCompany(agency.id).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Agency ${agency.name} removed`});
            this.loadItems();
          });
        }
      });
    }
  }

  setProducts(agency: any): void {
    this.teamCompaniesService.getCompany(agency.id).then(company => {
      const dialogRef = this.dialog.open(CompanyProductsDialogComponent, {
        data: new CompanyProductsDialogModel(company.payrollConfig.products, rememberedBrokerId)
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult !== undefined) {
          this.teamCompaniesService.modifyCompany(agency.id, {payrollConfig: _.merge(company.payRollConfig, { products: dialogResult})}).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Products set for ${agency.name}`});
          });
        }
      });
    });
  }

}
