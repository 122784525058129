<table class="table" mat-table matSort [dataSource]="permissions">
    <span *ngIf="permissions.data.length === 0">You do not have any permissions granted.</span>
    <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Company</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.companyName}}</td>
    </ng-container>
    <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Role</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.role}}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Created At</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.createdAt | date: 'medium'}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Created By</th>
        <td class="cell" mat-cell *matCellDef="let item"><a [href]="'mailto:'+item.email">{{item.name}}</a></td>
    </ng-container>
     <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
