import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {Location} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import { FeeService } from 'src/app/services/fee/fee.service';

@Component({
  selector: 'app-one-time-reward-dialog',
  templateUrl: './team-one-time-reward.page.html',
  styleUrls: ['./team-one-time-reward.page.scss'],
})
export class TeamOneTimeRewardComponent implements OnInit {

  public members: Array<any> = [];
  public form;
  public sendButtonLabel = 'No Members Selected';
  public team: any;
  public uploading = false;
  public isP2p: boolean;
  public title = '';
  public toTeam: any;

  serviceFee = 0;

  constructor(
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
      private stripeService: StripeService,
      private uiAlertService: UiAlertService,
      private teamsService: TeamsService,
      private location: Location,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<TeamOneTimeRewardComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TeamOneTimeRewardComponentModel,
      private feeService: FeeService,
  ) {
    this.isP2p = data.isP2p;
    this.toTeam = data.toTeam;
    this.title = this.isP2p ? 'Top Up Peer Recognition Balance for Team ' : 'Send One Time Payment to Team ';
    if (this.toTeam) {
      this.title = 'Send Wallit-Funded Allowance to Team ';
    }
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
      note: new UntypedFormControl(''),
      destination: new UntypedFormControl(false, [Validators.required]),
    });
    this.form.get('destination')?.setValue(false);
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      this.team = wellspace;
      if (!this.toTeam) {
        this.toTeam = wellspace;
      }
      this.title += this.toTeam.name;
    });
  }

  ngOnInit(): void {
  }

  membersChanged(event: any): void {
    this.members = event;
    this.sendButtonLabel = `Send to ${this.members.length} Member${this.members.length === 1 ? '' : 's'}`;
  }

  setButtonCompletionMessage(count: number): void {
    this.uploading = true;
    this.sendButtonLabel = `${count} of ${this.members.length} Sent`;
  }

  async sendRewards(): Promise<void> {
    const amount = this.form.get('amount')?.value * this.members.length;

    this.serviceFee = await this.feeService.getPaymentMethodFee(this.team);

    const amountPlusFee = amount * (1 + this.serviceFee);
    const paymentMethodDescription = 'default payment method';
    this.uiAlertService.presentAlertConfirm(`This will initiate a one time ${this.team.invoiceEmail ? 'invoice' : 'charge'} of $${amountPlusFee.toFixed(2)} to your ${paymentMethodDescription}. ${this.serviceFee > 0 ? `This includes a service fee of $${(amountPlusFee - amount).toFixed(2)}.` : ''}`).then(async (confirm: any) => {
      if (confirm) {
        let count = 0;
        this.setButtonCompletionMessage(0);
        const customerId = await this.teamsService.getCustomerId(this.team);
        await this.stripeService.makeOneTimePayment(customerId,
          `${this.team.name}: One time payment to `, amount / this.members.length,
           this.members.map(member => member.email), this.form.get('message')?.value, this.form.get('note')?.value, await this.teamsService.getPaymentMethod(this.team), this.team.id, this.isP2p, this.toTeam.id, this.form.get('destination')?.value).then(_ => {
        }).then(() => {
          this.setButtonCompletionMessage(++count);
        }).catch(error => {
          const info = error.error;
          this.snackBar.openFromComponent(SnackBarComponent, {data: `${info.raw.message}: ${info.raw.decline_code.split('_').join(' ')}`});
          this.uploading = false;
          this.dialogRef.close(false);
        });
        this.snackBar.openFromComponent(SnackBarComponent, { data: `One time payment made. If your default payment method is a bank, this may take a few days.`});
        this.dialogRef.close(true);
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}

export class TeamOneTimeRewardComponentModel {
  constructor(public isP2p: boolean, public toTeam: any) {
  }
}
