<div class="topline">
    <mat-form-field>
        <mat-label>Invitation Filter</mat-label>
        <mat-select (selectionChange)="setEmailFilter($event)">
            <mat-option value="all">All</mat-option>
            <mat-option value="invited">Invited</mat-option>
            <mat-option value="signedup">Signed Up</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<app-member-manager [reload]="reload" [table]="table" [companyId]="clientId" state="enrolled"></app-member-manager>
